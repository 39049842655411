import { APP_BASE_HREF, CommonModule, ViewportScroller, isPlatformBrowser } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
  NgZone,
  PLATFORM_ID,
  isDevMode,
} from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { BrowserModule, DomSanitizer, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { HeaderModule } from '@commons/header/header.component';
import { LogoModule } from '@commons/logo/logo.component';
import { NotificationsModule } from '@commons/notifications/notifications.module';
import { SharedModule } from '@commons/shared.module';
import { TrustBoxWidgetComponent } from '@commons/trust-box-widget/trust-box-widget.component';
import { bugsnagConfig } from '@config/bugsnag.config';
import { CoreComponent } from '@core/components/core/core.component';
import { ngxsConfig } from '@core/ngxs.config';
import { AppBrowserViewportScroller } from '@core/scroller/app-browser-viewport-scroller';
import { WizbiiRouterStateSerializer } from '@core/serializer/router.serializer';
import { JwtService } from '@core/services/jwt.service';
import { environment } from '@environment';
import { FeaturesModule } from '@features/features.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ConsentsState } from '@stores/consents/consents.state';
import { ServicesState } from '@stores/services/services.state';
import { SessionState } from '@stores/session/session.state';
import { WINDOW_PROVIDERS } from '@wizbii-utils/angular/core';
import { JWT_SERVICE_TOKEN, JwtInterceptor } from '@wizbii-utils/angular/jwt';
import { ACCOUNT_API_CONFIG, AccountWebservice } from '@wizbii-utils/angular/webservices';
import { DataStorageService } from '@wizbii/angular-utilities';
import { CookieService } from 'ngx-cookie-service';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';

Bugsnag.start(bugsnagConfig);

@NgModule({
  imports: [QuicklinkModule],
  exports: [QuicklinkModule],
})
export class QuicklinkSharedModule {}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    SharedModule.forRoot(),
    MatIconModule,
    QuicklinkSharedModule,
    RouterModule.forRoot([], {
      initialNavigation: 'enabledBlocking',
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      preloadingStrategy: QuicklinkStrategy,
    }),
    NgxsModule.forRoot([SessionState, ConsentsState, ServicesState], ngxsConfig),
    NgxsRouterPluginModule.forRoot(),
    ScrollToModule.forRoot(),
    HttpClientModule,
    MatProgressBarModule,
    FeaturesModule,
    HeaderModule,
    NotificationsModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({ name: 'WIZBII Account', maxAge: 30 }),

    StoreModule.forRoot(),
    EffectsModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      connectInZone: false, // If set to true, the connection is established within the Angular zone
      name: 'account-ngrx',
    }),
    LogoModule,
    TrustBoxWidgetComponent,
    TrustBoxWidgetComponent,
  ],
  providers: [
    DataStorageService,
    CookieService,
    {
      provide: ErrorHandler,
      useFactory: () => new BugsnagErrorHandler(),
    },

    { provide: RouterStateSerializer, useClass: WizbiiRouterStateSerializer },

    { provide: ViewportScroller, useClass: AppBrowserViewportScroller },
    { provide: LOCALE_ID, useValue: environment.lang },
    { provide: APP_BASE_HREF, useValue: '/account/' },

    WINDOW_PROVIDERS,
    { provide: JWT_SERVICE_TOKEN, useClass: JwtService },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },

    AccountWebservice,
    {
      provide: ACCOUNT_API_CONFIG,
      useFactory: (platformId: any) => ({
        appId: environment.applicationId,
        baseUrl: isPlatformBrowser(platformId) ? '/_api/account-api' : 'http://account-api',
        locale: environment.locale,
        cookieDomains: environment.domainsByLocale,
        platform: environment.platform,
        defaultCookieDomain: environment.platform === 'local' ? 'localhost' : undefined,
      }),
      deps: [PLATFORM_ID],
    },
  ],
  declarations: [CoreComponent],
  bootstrap: [CoreComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {
  constructor(ngZone: NgZone, matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    ngZone.runOutsideAngular(() => {
      const domain = environment.deployAssetsUrl ?? '/account/assets';
      matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl(`${domain}/sprite.svg`));
    });
  }
}
