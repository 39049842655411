/* eslint-disable */
import { buildConfig } from './build-config';
import { getBuildEnvVar, getRuntimeEnvVar } from '@wizbii-utils/angular/core';
import { LangEnum, LocaleEnum } from '@wizbii/utils/models';

const packageJsonVersionAtBuild = require('../../package.json').version; // eslint-disable-line

// @ts-ignore
const production: boolean = buildConfig === 'production';
const ciCommitSha = getBuildEnvVar('CI_COMMIT_SHA');
const analyticsUai = getRuntimeEnvVar('WIZBII_ANALYTICS_UAI');
const googleAnalyticsUai = getRuntimeEnvVar('GOOGLE_ANALYTICS_UAI');

const wizbiiDomain = getRuntimeEnvVar('WIZBII_DOMAIN');
const wizbiiDomainIt = getRuntimeEnvVar('WIZBII_DOMAIN_ITALY');
const wizbiiDomainEs = getRuntimeEnvVar('WIZBII_DOMAIN_SPAIN');

const oldAccount = `https://account-v1.${wizbiiDomain}`;

const deployAssetsUrl = production
  ? `https://storage.googleapis.com/wizbii-application-assets/account-v2/${getBuildEnvVar('CI_COMMIT_SHA')}/fr/assets`
  : null;

export const environment = {
  applicationId: 'account',
  contactEmailKey: 'account',
  deployAssetsUrl,
  platform: getRuntimeEnvVar('PLATFORM'),
  domain: wizbiiDomain,
  isDeployed: ciCommitSha !== 'local',
  isProdBuild: production,
  version: production ? packageJsonVersionAtBuild : getBuildEnvVar('GIT_BRANCH_NAME'),
  lang: getBuildEnvVar('LANG'),
  locale: getBuildEnvVar('LOCALE'),
  bugsnagId: '284a6cb2f6672f0a5b0d0d0c11c9654a',
  domainsByLang: {
    [LangEnum.fr]: wizbiiDomain,
    [LangEnum.en]: wizbiiDomain,
    [LangEnum.it]: wizbiiDomainIt,
    [LangEnum.es]: wizbiiDomainEs,
  },
  domainsByLocale: {
    [LocaleEnum.fr_FR]: wizbiiDomain,
    [LocaleEnum.en_GB]: wizbiiDomain,
    [LocaleEnum.it_IT]: wizbiiDomainIt,
    [LocaleEnum.es_ES]: wizbiiDomainEs,
  },
  api: {
    googleStorage: 'https://storage.googleapis.com',
    sso: `https://sso.internal.${wizbiiDomain}`,
  },
  urls: {
    oldAccount,
    driveUrl: `https://drive.${wizbiiDomain}`,
  },
  analytics: {
    waTrackingId: analyticsUai,
    gaTrackingId: googleAnalyticsUai,
    gaConversionId: {
      [LocaleEnum.fr_FR]: 'AW-874490256',
      [LocaleEnum.en_GB]: 'AW-874490256',
      [LocaleEnum.es_ES]: 'AW-10866647616',
      [LocaleEnum.it_IT]: 'AW-10866697118',
    },
    hotjar: {
      id: '2048440',
      version: '6',
    },
    yahoo: {
      projectId: '10000',
      pixelId: '10131589',
    },
    twitter: {
      twitterId: getRuntimeEnvVar('TWITTER_PIXEL_ID'),
      version: getRuntimeEnvVar('TWITTER_PIXEL_VERSION'),
    },
    pinterest: {
      pixelId: '2612995435269',
      pixelVersion: '3.0',
    },
    outbrain: {
      pixelId: '00b438cb0a514efa94cda1579bc7c53b0e',
      pixelVersion: '1.1',
    },
    criteo: {
      appId: '59994',
    },
  },
  signupUrls: {
    jobs: `https://www.${wizbiiDomain}`,
    drive: `https://app.drive.${wizbiiDomain}/sign-up/1`,
    account: `https://www.${wizbiiDomain}/account/sign-up`,
  },
  tiktok: {
    [LocaleEnum.fr_FR]: {
      'money-intl': 'C6BP84RA1SKIVFQVLF0G',
      fibii: 'C6BP84RA1SKIVFQVLF0G',
      fibii_nexity: 'C6BP84RA1SKIVFQVLF0G',
      'permii-app': 'C5KOHNL1T98720DVT9OG',
      jobs: 'C5P8C9DODNNK397VRGCG',
    },
    [LocaleEnum.en_GB]: {
      'money-intl': 'C6BP84RA1SKIVFQVLF0G',
      fibii: 'C6BP84RA1SKIVFQVLF0G',
      fibii_nexity: 'C6BP84RA1SKIVFQVLF0G',
      'permii-app': 'C5KOHNL1T98720DVT9OG',
      jobs: 'C5P8C9DODNNK397VRGCG',
    },
    [LocaleEnum.es_ES]: {
      'money-intl': 'C96RAA3C77U7KEOR57MG',
      fibii: 'C96RAA3C77U7KEOR57MG',
      fibii_nexity: 'C96RAA3C77U7KEOR57MG',
      'permii-app': 'C96RDOBC77U37LFVIRO0',
    },
    [LocaleEnum.it_IT]: {
      'money-intl': 'C96RFBRC77U7KEOR57TG',
      fibii: 'C96RFBRC77U7KEOR57TG',
      fibii_nexity: 'C96RFBRC77U7KEOR57TG',
      'permii-app': 'C96RH7JC77U6S6FS2HV0',
    },
  },
  facebookLogin: {
    appId: getRuntimeEnvVar('FACEBOOK_CONNECT_KEY'),
    version: getRuntimeEnvVar('FACEBOOK_CONNECT_VERSION'),
  },
  facebookPixel: {
    [LocaleEnum.fr_FR]: {
      'permii-app': {
        id: '609008116315473',
        version: '2.0',
      },
      jobs: {
        id: '727985974263933',
        version: '2.0',
      },
      'money-intl': {
        id: '989496764853730',
        version: '2.0',
      },
      fibii: {
        id: '989496764853730',
        version: '2.0',
      },
      fibii_nexity: {
        id: '989496764853730',
        version: '2.0',
      },
    },
    [LocaleEnum.en_GB]: {
      'permii-app': {
        id: '609008116315473',
        version: '2.0',
      },
      jobs: {
        id: '727985974263933',
        version: '2.0',
      },
      'money-intl': {
        id: '989496764853730',
        version: '2.0',
      },
      fibii: {
        id: '989496764853730',
        version: '2.0',
      },
      fibii_nexity: {
        id: '989496764853730',
        version: '2.0',
      },
    },
    [LocaleEnum.es_ES]: {
      'permii-app': {
        id: '310723737848109',
        version: '2.0',
      },
      'money-intl': {
        id: '381739360280604',
        version: '2.0',
      },
      fibii: {
        id: '381739360280604',
        version: '2.0',
      },
      fibii_nexity: {
        id: '381739360280604',
        version: '2.0',
      },
    },
    [LocaleEnum.it_IT]: {
      'permii-app': {
        id: '494844358855942',
        version: '2.0',
      },
      'money-intl': {
        id: '704368087678574',
        version: '2.0',
      },
      fibii: {
        id: '704368087678574',
        version: '2.0',
      },
      fibii_nexity: {
        id: '704368087678574',
        version: '2.0',
      },
    },
  },
  neuvoo: {
    jobs: 'wizbii',
  },
  bingPixel: {
    [LocaleEnum.fr_FR]: {
      jobs: '56348309',
      'permii-app': '56286382',
      'money-intl': '56314483',
      fibii: '56314483',
      fibii_nexity: '56314483',
    },
    [LocaleEnum.en_GB]: {
      jobs: '56348309',
      'permii-app': '56286382',
      'money-intl': '56314483',
      fibii: '56314483',
      fibii_nexity: '56314483',
    },
    [LocaleEnum.es_ES]: {
      jobs: '149007515',
      'permii-app': '149007515',
      'money-intl': '149007515',
      fibii: '149007515',
      fibii_nexity: '149007515',
    },
    [LocaleEnum.it_IT]: {
      jobs: '149007516',
      'permii-app': '149007516',
      'money-intl': '149007516',
      fibii: '149007516',
      fibii_nexity: '149007516',
    },
  },
  snapchatPixel: {
    [LocaleEnum.fr_FR]: {
      'permii-app': '53b582ff-646b-4a59-b29e-6f5650857cd3',
      'money-intl': '82e21569-d654-4a1e-bb29-ff9497f89a7c',
      fibii: '82e21569-d654-4a1e-bb29-ff9497f89a7c',
      fibii_nexity: '82e21569-d654-4a1e-bb29-ff9497f89a7c',
    },
    [LocaleEnum.en_GB]: {
      'permii-app': '53b582ff-646b-4a59-b29e-6f5650857cd3',
      'money-intl': '82e21569-d654-4a1e-bb29-ff9497f89a7c',
      fibii: '82e21569-d654-4a1e-bb29-ff9497f89a7c',
      fibii_nexity: '82e21569-d654-4a1e-bb29-ff9497f89a7c',
    },
    [LocaleEnum.es_ES]: {
      'permii-app': 'fbb29bf5-cc19-47c0-ac49-4ffa55fd3302',
      'money-intl': '57ffe7b3-b1aa-4b8d-a535-a69441e2931f',
      fibii: '57ffe7b3-b1aa-4b8d-a535-a69441e2931f',
      fibii_nexity: '57ffe7b3-b1aa-4b8d-a535-a69441e2931f',
    },
    [LocaleEnum.it_IT]: {
      'permii-app': '67ace15b-e78d-44d9-a887-5d9a737f8971',
      'money-intl': '335e7a86-1566-41dd-8d2a-06f7e5254185',
      fibii: '335e7a86-1566-41dd-8d2a-06f7e5254185',
      fibii_nexity: '335e7a86-1566-41dd-8d2a-06f7e5254185',
    },
  },
  awin: {
    merchantId: '41878',
  },
  googleTagManagerId: {
    'permii-app': {
      id: 'GTM-NLFD893',
    },
    'money-intl': {
      id: 'GTM-MMF3MGV',
    },
    fibii: {
      id: 'GTM-MMF3MGV',
    },
    fibii_nexity: {
      id: 'GTM-MMF3MGV',
    },
  },
  emailPreferencesToken: getRuntimeEnvVar('WIZBII_EMAIL_PREFERENCES_TOKEN'),
  googleLoginProvider: getRuntimeEnvVar('GOOGLE_CONNECT_KEY'),
  wizbiiFiles: getRuntimeEnvVar('WIZBII_FILES_GCS_BUCKET'),
  appleLogin: {
    redirectUri: `https://www.${wizbiiDomain}/account/_hook/apple`,
    serviceBundle: getRuntimeEnvVar('APPLE_CONNECT_SERVICE_BUNDLE'),
  },
};
